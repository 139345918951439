import { navigate } from 'gatsby';
import { useEffect } from 'react';

function NotFoundPage() {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
}

export default NotFoundPage;
